import React, { useState, useEffect } from "react";
import { Form, Row, Container, Card, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { RootState, AppDispatch } from "../../../redux/store";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../utils/ApiMethod";
import { API_BASE_URL } from "../../../apiservices/apiService";

interface TestItem {
  _id: string;
  SN: number;
  name: string;
  sports: string;
  date_of_birth: string;
  date: string;
  result: string;
  rank: number;
  player: any;
  playerdata: any;
  tests: [];
  consultant_name: string;
}
const ViewResult = () => {
  const { playerId } = useParams();
  // const { playerId } = useParams<{ playerId: string }>(); // Extracting playerId from URL params
  const [playerdata, setPlayerData] = useState<TestItem[]>([]);
  const { user, userLoggedIn, loading, error, _id } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      _id: state.Auth._id,
    })
  );

  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user?.moniterd_ttl
    : user?.user
    ? user?.user?.moniterd_ttl
    : "";

  // const [playerdata, setPlayerData] = useState();
  const fetchData = async () => {
    try {
      const response = await api.fetchData(
        `${API_BASE_URL}/api/student/tests/${playerId}`
      );

      const data = response.data.formattedTests;
      // console.log(data, "responsedata---- - ---");
      setPlayerData(data);
      if (data.status) {
        // console.log("Tests retrieved successfully:", data.tests);
      } else {
        console.error("Error retrieving tests:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [playerId]);
  // console.log(playerdata, "playerdata---- - ---");
  const calculateAge = (dob: string) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dobDate.getFullYear();
    const monthDiff = currentDate.getMonth() - dobDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dobDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const formatDate = (date: string) => {
    const formattedDate = new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  type AgreeablenessValue =
    | "Strongly Disagree"
    | "Disagree"
    | "Neutral"
    | "Agree"
    | "Strongly Agree";

  const calculateAgreeablenessScore = (
    agreeablenessObject: Record<number, AgreeablenessValue>
  ) => {
    const agreeablenessArray = Object.values(agreeablenessObject);

    if (!Array.isArray(agreeablenessArray)) {
      console.error("Agreeableness data is not an array");
      return 0; // Return 0 or some default value if the array is not valid
    }

    const pointsMapping: Record<AgreeablenessValue, number> = {
      "Strongly Disagree": 1,
      Disagree: 2,
      Neutral: 3,
      Agree: 4,
      "Strongly Agree": 5,
    };

    let totalScore = 0;

    agreeablenessArray.forEach((value) => {
      totalScore += pointsMapping[value] || 0;
    });

    return totalScore;
  };

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <div className="container">
            <h1 className="text-center mt-4 mb-4">Result</h1>
            {playerdata.map((item: any, i: any) => (
              <div key={i}>
                <h6>Name: {item.player.name}</h6>
                <h6>Sport: {item.player.sports}</h6>{" "}
                {/* Assuming the sport is stored in item.sports */}
              </div>
            ))}
            {playerdata.map((item: any, i: any) => (
              <div key={i}>
                <h6>
                  Openness:{" "}
                  {calculateAgreeablenessScore(item.player.big_five.Openness)}{" "}
                  out of 50
                </h6>
                <p>
                  <strong> High Score:</strong> Individuals with higher scores
                  on Openness tend to be imaginative, creative, and open-minded.
                  They are curious about the world around them, enjoy exploring
                  new ideas and experiences, and are often seen as innovative
                  and unconventional.
                  <br />
                  <strong> Low Score:</strong> Individuals with lower scores on
                  Openness are more traditional, practical, and prefer
                  familiarity over novelty. They may be more resistant to
                  change, less interested in exploring new ideas, and may prefer
                  routines and predictability in their lives.
                </p>
              </div>
            ))}
            {playerdata.map((item: any, i: any) => (
              <div key={i}>
                <h6>
                  Conscientiousness:{" "}
                  {calculateAgreeablenessScore(
                    item.player.big_five.Conscientiousness
                  )}{" "}
                  out of 50
                </h6>
                <p>
                  <strong> High Score:</strong> Individuals with higher scores
                  on Conscientiousness are organized, responsible, and reliable.
                  They are diligent in their work, pay attention to detail, and
                  are committed to achieving their goals. They are often seen as
                  dependable and efficient.
                  <br />
                  <strong> Low Score:</strong> Individuals with lower scores on
                  Conscientiousness may be more laid-back, spontaneous, and less
                  focused on planning and structure. They may struggle with time
                  management, have difficulty meeting deadlines, and may be
                  perceived as less dependable in their responsibilities.
                </p>
              </div>
            ))}
            {playerdata.map((item: any, i: any) => (
              <div key={i}>
                <h6>
                  Extraversion:{" "}
                  {calculateAgreeablenessScore(
                    item.player.big_five.Extraversion
                  )}{" "}
                  out of 50
                </h6>
                <p>
                  <strong> High Score:</strong> Individuals with higher scores
                  on Extraversion are outgoing, sociable, and energetic. They
                  enjoy social interactions, thrive in group settings, and are
                  often the life of the party. They are assertive and confident
                  in their interactions with others.
                  <br />
                  <strong> Low Score:</strong> Individuals with lower scores on
                  Extraversion are more reserved, introspective, and prefer
                  solitude over socializing. They may be quieter and more
                  reserved in social settings, preferring to spend time alone or
                  with a small circle of close friends.
                </p>
              </div>
            ))}
            {playerdata.map((item: any, i: any) => (
              <div key={i}>
                <h6>
                  Agreeableness:{" "}
                  {calculateAgreeablenessScore(
                    item.player.big_five.Agreeableness
                  )}{" "}
                  out of 50
                </h6>
                <p>
                  <strong> High Score:</strong>Individuals with higher scores on
                  Agreeableness are compassionate, empathetic, and cooperative.
                  They value harmony in relationships, are considerate of
                  others' feelings, and are willing to compromise to avoid
                  conflict. They are often seen as warm and caring individuals.
                  <br />
                  <strong> Low Score:</strong> Individuals with lower scores on
                  Agreeableness may be more competitive, skeptical, and less
                  concerned with others' well-being. They may prioritize their
                  own interests over those of others and may be more inclined to
                  engage in confrontational or argumentative behavior.
                </p>
              </div>
            ))}
            {playerdata.map((item: any, i: any) => (
              <div key={i}>
                <h6>
                  Neuroticism:{" "}
                  {calculateAgreeablenessScore(
                    item.player.big_five.Neuroticism
                  )}{" "}
                  out of 50
                </h6>
                <p>
                  <strong> High Score:</strong>Individuals with higher scores on
                  Neuroticism tend to experience higher levels of negative
                  emotions such as anxiety, depression, and irritability. They
                  may be more prone to stress, worry, and mood swings, and may
                  have difficulty coping with adversity.
                  <br />
                  <strong> Low Score:</strong>Individuals with lower scores on
                  Neuroticism are more emotionally stable, resilient, and able
                  to maintain a positive outlook in the face of challenges. They
                  are less likely to experience intense negative emotions and
                  are better able to cope with stress and setbacks.
                </p>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ViewResult;
