import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";

interface StudentFormData {
  studentName: string;
  gender: "Female" | "Male";
  aadharNumber: string;
  fatherName: string;
  motherName: string;
  dob: string;
  caldob: string;
  ageCategory: "U-14 (Boys)" | "U-14 (Girls)" | "U-16 (Boys)" | "U-16 (Girls)";
  mobileNumber: string;
  email: string;
  gameName: string[];
  height: string;
  weight: string;
  verticalJump: string;
  thirtyMeterSprint: string;
  mBallThrow: string;
  shuttleRun: string;
  standingBroadJump: string;
  eightHundredMetersRun: string;
}

const gameOptions = {
  "U-14": [
    "Football",
    "Kabaddi",
    "Cycling",
    "Longjump (Athletics)",
    "Highjump (Athletics)",
    "60m (Athletics)",
    "600m (Athletics)",
  ],
  "U-16": [
    "Football",
    "Kabaddi",
    "Cycling",
    "Longjump (Athletics)",
    "Highjump (Athletics)",
    "100m (Athletics)",
    "800m (Athletics)",
  ],
};

const ProfileStudentRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState<StudentFormData>({
    studentName: "",
    gender: "Female",
    aadharNumber: "",
    fatherName: "",
    motherName: "",
    dob: "",
    caldob: "",
    ageCategory: "U-14 (Boys)",
    mobileNumber: "",
    email: "",
    gameName: [],
    height: "",
    weight: "",
    verticalJump: "",
    thirtyMeterSprint: "",
    mBallThrow: "",
    shuttleRun: "",
    standingBroadJump: "",
    eightHundredMetersRun: "",
  });

  const dobRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (formData.dob) {
      const birthDate = new Date(formData.dob);
      const currentDate = new Date("2023-12-31");
      const age = currentDate.getFullYear() - birthDate.getFullYear();
      const month = currentDate.getMonth() - birthDate.getMonth();
      const caldobText = `${age} years ${
        month < 0 ? 12 + month : month
      } months`;

      let ageCategory: StudentFormData["ageCategory"];
      if (age < 14) {
        ageCategory =
          formData.gender === "Female" ? "U-14 (Girls)" : "U-14 (Boys)";
      } else {
        ageCategory =
          formData.gender === "Female" ? "U-16 (Girls)" : "U-16 (Boys)";
      }

      setFormData((prevData) => ({
        ...prevData,
        caldob: caldobText,
        ageCategory: ageCategory,
        gameName: [], // Reset game selection on age category change
      }));
    }
  }, [formData.dob, formData.gender]);

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleGameChange = (selectedOptions: any) => {
    const selectedGames = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];
    setFormData((prevData) => ({
      ...prevData,
      gameName: selectedGames,
    }));
  };

  const getAvailableGames = () => {
    const ageGroup = formData.ageCategory.startsWith("U-14") ? "U-14" : "U-16";
    const allGames = gameOptions[ageGroup];

    const selectedGames = formData.gameName;
    const athleticsGamesSelected = selectedGames.filter((game) =>
      game.includes("(Athletics)")
    ).length;

    const isOtherGameSelected = selectedGames.some(
      (game) => !game.includes("(Athletics)")
    );

    return allGames.map((game) => ({
      value: game,
      label: game,
      isDisabled:
        isOtherGameSelected ||
        (athleticsGamesSelected >= 2 && game.includes("(Athletics)")) ||
        (athleticsGamesSelected > 0 && !game.includes("(Athletics)")),
    }));
  };

  const availableGames = getAvailableGames();

  //   console.log(availableGames, "availableGames array");

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      maxHeight: 200,
      overflowY: "auto",
    }),
  };

  useEffect(() => {
    const dobInput = dobRef.current;
    const handleFocus = () => {
      if (
        dobInput instanceof HTMLInputElement &&
        typeof dobInput.focus === "function"
      ) {
        dobInput.focus();
      }
    };

    if (dobInput) {
      dobInput.addEventListener("focus", handleFocus);
    }

    return () => {
      if (dobInput) {
        dobInput.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <h2
            className="header-title mt-4 mb-4 p-2 text-center fs-19"
            style={{ border: "2px solid grey", borderRadius: "5px" }}
          >
            Student Profile Details
          </h2>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formStudentName">
              <Form.Label column md={4} className="text-md-start">
                Student Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="studentName"
                  value={formData.studentName}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formGender">
              <Form.Label column md={4} className="text-md-start">
                Gender <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <div>
                  <Form.Check
                    inline
                    label="Girl"
                    name="gender"
                    type="radio"
                    id="genderFemale"
                    value="Female"
                    checked={formData.gender === "Female"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    label="Boy"
                    name="gender"
                    type="radio"
                    id="genderMale"
                    value="Male"
                    checked={formData.gender === "Male"}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formAadharNumber">
              <Form.Label column md={4} className="text-md-start">
                Aadhar Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="aadharNumber"
                  value={formData.aadharNumber}
                  onChange={handleChange}
                  maxLength={12}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formFatherName">
              <Form.Label column md={4} className="text-md-start">
                Father's Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="fatherName"
                  value={formData.fatherName}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formMotherName">
              <Form.Label column md={4} className="text-md-start">
                Mother's Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="motherName"
                  value={formData.motherName}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formDOB">
              <Form.Label column md={4} className="text-md-start">
                Date of Birth <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                  ref={dobRef}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formAgeAsOn">
              <Form.Label column md={4} className="text-md-start">
                Age as on 31st Dec 2023
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="caldob"
                  value={formData.caldob}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCategory">
              <Form.Label column md={4} className="text-md-start">
                Category
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="ageCategory"
                  value={formData.ageCategory}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formMobileNumber">
              <Form.Label column md={4} className="text-md-start">
                Mobile Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  maxLength={10}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formEmail">
              <Form.Label column md={4} className="text-md-start">
                E-Mail Id
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formGameName">
              <Form.Label column md={4} className="text-md-start">
                Select Your Game <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Select
                  isMulti
                  name="gameName"
                  options={availableGames}
                  value={availableGames.filter((game) =>
                    formData.gameName.includes(game.value)
                  )}
                  onChange={handleGameChange}
                  closeMenuOnSelect={false}
                  styles={customStyles}
                />
                <small className="form-text text-muted">
                  Max 2 Athletics events can be selected.
                </small>
              </Col>
            </Form.Group>
            <h2
              className="header-title mt-4 mb-4 p-2 text-center fs-19"
              style={{ border: "2px solid grey", borderRadius: "5px" }}
            >
              Battery Test Details
            </h2>

            <Row className="mb-3">
              <Col md={3}>
                <Form.Group controlId="formHeight">
                  <Form.Label>Height (Cm)</Form.Label>
                  <Form.Control
                    type="text"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formWeight">
                  <Form.Label>Weight (Kg)</Form.Label>
                  <Form.Control
                    type="text"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formVerticalJump">
                  <Form.Label>Vertical Jump (cm)</Form.Label>
                  <Form.Control
                    type="text"
                    name="verticalJump"
                    value={formData.verticalJump}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formThirtyMeterSprint">
                  <Form.Label>30 Mtr sprint(sec)</Form.Label>
                  <Form.Control
                    type="text"
                    name="thirtyMeterSprint"
                    value={formData.thirtyMeterSprint}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={3}>
                <Form.Group controlId="formMBallThrow">
                  <Form.Label>M-Ball Throw</Form.Label>
                  <Form.Control
                    type="text"
                    name="mBallThrow"
                    value={formData.mBallThrow}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formShuttleRun">
                  <Form.Label>6X10 Mtr shuttle run</Form.Label>
                  <Form.Control
                    type="text"
                    name="shuttleRun"
                    value={formData.shuttleRun}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formStandingBroadJump">
                  <Form.Label>Standing Broad jump</Form.Label>
                  <Form.Control
                    type="text"
                    name="standingBroadJump"
                    value={formData.standingBroadJump}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formEightHundredMetersRun">
                  <Form.Label>800 mts Run</Form.Label>
                  <Form.Control
                    type="text"
                    name="eightHundredMetersRun"
                    value={formData.eightHundredMetersRun}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 8, offset: 4 }}>
                <Button type="submit" className="btn btn-primary">
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProfileStudentRegistrationForm;
