import React, { useState, useEffect } from "react";
import { Form, Row, Container, Card, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { RootState, AppDispatch } from "../../../redux/store";
import api from "../../../utils/ApiMethod";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/actions";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../apiservices/apiService";

interface TestItem {
  _id: string;
  SN: number;
  name: string;
  sports: string;
  date_of_birth: string;
  date: string;
  result: string;
  rank: number;
  player: any;
  playerdata: any;
  tests: [];
  consultant_name: string;
}
const ViewProfileDetails = () => {
  // const dispatch = useDispatch<AppDispatch>();

  const { playerId } = useParams();
  // const { playerId } = useParams<{ playerId: string }>(); // Extracting playerId from URL params
  const [playerdata, setPlayerData] = useState<TestItem[]>([]);
  const { user, userLoggedIn, loading, error, _id } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      _id: state.Auth._id,
    })
  );

  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user?.moniterd_ttl
    : user?.user
    ? user?.user?.moniterd_ttl
    : "";
  const fetchData = async () => {
    try {
      const response = await api.fetchData(
        `${API_BASE_URL}/api/student/tests/${playerId}`
      );

      const data = response.data.formattedTests;

      setPlayerData(data);
      if (data.status) {
        // console.log("Tests retrieved successfully:", data.tests);
      } else {
        console.error("Error retrieving tests:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [playerId]);

  // console.log(playerdata, "setPlayerData---- - ---");

  interface Questions {
    [key: string]: string[];
  }

  interface Responses {
    [key: string]: {
      [key: number]: string;
    };
  }

  const initialResponses: Responses = {
    Openness: {},
    Conscientiousness: {},
    Extraversion: {},
    Agreeableness: {},
    Neuroticism: {},
  };
  const [formData, setFormData] = useState({
    date: "",
    consultantName: "",
    playerName: "",
    sports: "",
    playingSince: "",
    dob: "",
    gender: "",
    academicBackground: "",
    currentLevelOfCompetition: "",
    highestLevelOfCompetition: "",
    highestScoreAchieved: "",
    coachName: "",
    yearsTrainingUnderCurrentCoach: "",
    psychologicalAssistance: "",
    psychologicalAssistanceReason: "",
    selfAssessment: "",
    satisfactionWithPerformance: "",
    helpToMaintainImprovePerformance: "",
    reasonsForDissatisfaction: "",
    previousExperience: "",
    sportsInjury: "",
    injuryTreatment: "",
    healingStatusOfInjury: "",
    injuryProneness: "",
    coachDescription: "",
    coachSupportReasons: "",
    coachNonSupportReasons: "",
    conflictWithCoach: "",
    conflictReasonWithCoach: "",
    conflictResolutionWithCoach: "",
    conflictWithCoPlayers: "",
    conflictReasonWithCoPlayers: "",
    conflictResolutionWithCoPlayers: "",
    physicalSkills: {
      flexibility: "",
      agility: "",
      arm_strength: "",
      thighs_strength: "",
      neuromuscular_coordination: "",
      body_movement_control: "",
      speed: "",
      stamina: "",
      other_physical_skills: "",
    },

    psychologicalSkills: {
      self_confidence: "",
      sustained_attention: "",
      role_efficacy: "",
      concentration: "",
      stress_management: "",
      rebound_ability: "",
      commitment_to_game: "",
      anxiety: "",
      other_psychological_skills: "",
    },
    bigFivePersonalityTest: {
      timeTaken: "",
      Openness: "",
      Conscientiousness: "",
      Extraversion: "",
      Agreeableness: "",
      Neuroticism: "",
      overallRemark: "",
    },
    consultantsObservations: "",
  });

  const sportsLevels = [
    "School– Zonal",
    "District",
    "State",
    "National",
    "College – Intercollegiate",
    "Interuniversity (all India)",
    "World university",
    "Open matches – Club",
    "State",
    "National",
    "International",
  ];

  interface SportsExperiences {
    level: string;
    year: string;
    medal: string;
    bestscore: string;
  }

  const [currentSection, setCurrentSection] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [sportsExperiences, setSportsExperiences] = useState<
    SportsExperiences[]
  >(
    sportsLevels.map((level) => ({ level, year: "", medal: "", bestscore: "" }))
  );

  const [responses, setResponses] = useState<Responses>(initialResponses);

  const handleNext = () => {
    setCurrentSection(currentSection + 1);
  };

  const handlePrev = () => {
    setCurrentSection(currentSection - 1);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const newValue = value.replace(/^\s+/, "");
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handlePhysicalSkillsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      physicalSkills: {
        ...formData.physicalSkills,
        [name]: value,
      },
    });
  };

  const handlePsychologicalSkillsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      psychologicalSkills: {
        ...formData.psychologicalSkills,
        [name]: value,
      },
    });
  };

  const sections: Questions = {
    Openness: [
      "I enjoy exploring new ideas and concepts.",
      "I am open to trying new methods or approaches to solving problems.",
      "I enjoy learning about different cultures and perspectives.",
      "I often find myself daydreaming or imagining possibilities.",
      "I enjoy engaging in creative activities such as painting or writing.",
      "I like to challenge traditional ways of thinking.",
      "I am interested in philosophical discussions.",
      "I am drawn to experiences that are unconventional or out of the ordinary.",
      "I enjoy visiting museums, art galleries, or attending cultural events.",
      "I am curious about the world around me and seek out new experiences.",
    ],
    Conscientiousness: [
      "I am highly organized and keep track of my tasks meticulously.",
      "I am diligent and hardworking, even when tasks are challenging.",
      "I am known for my punctuality and reliability.",
      "I pay close attention to detail in my work.",
      "I am committed to meeting deadlines and fulfilling my responsibilities.",
      "I am disciplined and can resist distractions when working on a task.",
      "I take a methodical approach to problem-solving.",
      "I set ambitious goals for myself and work persistently to achieve them.",
      "I am careful to follow rules and procedures in my work.",
      "I take pride in producing high-quality work and strive for excellence.",
    ],
    Extraversion: [
      "I enjoy socializing and meeting new people.",
      "I am outgoing and confident in social situations.",
      "I thrive in group settings and enjoy collaborating with others.",
      "I am often the life of the party and enjoy being in the spotlight.",
      "I am comfortable expressing my thoughts and opinions openly.",
      "I am energized by being around others and feel bored when alone for too long.",
      "I enjoy participating in team activities and group discussions.",
      "I am known for my enthusiastic and energetic demeanor.",
      "I am quick to strike up conversations with strangers.",
      "I am drawn to social events and enjoy being part of a lively atmosphere.",
    ],
    Agreeableness: [
      "I am considerate of others' feelings and opinions.",
      "I value cooperation and strive to maintain harmonious relationships.",
      "I am empathetic and sensitive to others' needs.",
      "I am known for my kindness and compassion toward others.",
      "I am patient and understanding, even in challenging situations.",
      "I am cooperative and enjoy working as part of a team.",
      "I am quick to forgive others and believe in giving second chances.",
      "I avoid confrontation and seek to resolve conflicts peacefully.",
      "I am supportive of my colleagues and enjoy helping them succeed.",
      "I am courteous and respectful in my interactions with others.",
    ],
    Neuroticism: [
      "I often experience anxiety or worry about the future.",
      "I tend to dwell on past mistakes or regrets.",
      "I am prone to mood swings and fluctuations in my emotions.",
      "I am sensitive to criticism and often take things personally.",
      "I find it difficult to relax and often feel tense or on edge.",
      "I worry excessively about things that are beyond my control.",
      "I am easily overwhelmed by stress or pressure.",
      "I tend to ruminate on negative thoughts or feelings.",
      "I struggle to maintain a positive outlook in challenging situations.",
      "I am highly sensitive to changes in my environment or circumstances.",
    ],
  };

  const handleRadioChange = (
    section: string,
    questionIndex: number,
    value: string
  ) => {
    setResponses((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [questionIndex]: value,
      },
    }));
  };

  const handleInputChange = (
    index: number,
    field: keyof SportsExperiences,
    value: string
  ) => {
    const updatedExperiences = [...sportsExperiences];
    updatedExperiences[index][field] = value;
    setSportsExperiences(updatedExperiences);
  };

  // console.log(responses, "RESPONSE");

  const renderQuestions = (section: string, questions: string[]) => {
    return questions.map((question: string, index: number) => (
      <Form.Group key={index} className="mb-3">
        <Row>
          <Col sm={12} className="d-flex">
            <strong>
              <Form.Label>
                {index + 1}. {question}
              </Form.Label>
            </strong>
          </Col>
        </Row>
        <Row>
          {[
            "Strongly Disagree",
            "Disagree",
            "Neutral",
            "Agree",
            "Strongly Agree",
          ].map((option, idx) => (
            <Col key={idx} sm={2}>
              <Form.Check
                type="radio"
                label={option}
                name={`${section}-${index}`}
                id={`${section}-${index}-${idx}`}
                value={option}
                onChange={() => handleRadioChange(section, index, option)}
                style={{ paddingLeft: "2.3em" }}
              />
            </Col>
          ))}
        </Row>
      </Form.Group>
    ));
  };

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   try {
  //     const formDataToSend = new FormData();

  //     formDataToSend.append("date", formData.date);
  //     formDataToSend.append("consultant_name", formData.consultantName);

  //     const playerData = {
  //       name: formData.playerName,
  //       gender: formData.gender,
  //       date_of_birth: formData.dob,
  //       academic_background: formData.academicBackground,
  //       sports: formData.sports,
  //       playing_since: formData.playingSince,
  //       current_level_of_competition: formData.currentLevelOfCompetition,
  //       highest_level_of_competition: formData.highestLevelOfCompetition,
  //       highest_score_achieved: formData.highestScoreAchieved,
  //       coachName: formData.coachName,
  //       years_of_training: formData.yearsTrainingUnderCurrentCoach,
  //       coachDescription: {
  //         support_reasons: formData.coachSupportReasons,
  //         non_support_reasons: formData.coachNonSupportReasons,
  //       },
  //       psychological_assistance: {
  //         provided: Boolean(formData.psychologicalAssistance),

  //         reason: formData.psychologicalAssistanceReason,
  //       },
  //       self_assessment: formData.selfAssessment,
  //       satisfaction_with_performance: Boolean(
  //         formData.satisfactionWithPerformance
  //       ),
  //       help_to_maintain_improve_performance:
  //         formData.helpToMaintainImprovePerformance,
  //       reasons_for_dissatisfaction: formData.reasonsForDissatisfaction,
  //       previous_experience: formData.previousExperience,
  //       sports_injury: {
  //         description: formData.sportsInjury,
  //         treatment: formData.injuryTreatment,
  //         healing_status: formData.healingStatusOfInjury,
  //         proneness: formData.injuryProneness,
  //       },
  //       conflict_with_co_players: Boolean(formData.conflictWithCoPlayers),
  //       conflict_reason_with_co_players: formData.conflictReasonWithCoPlayers,
  //       conflict_resolution_with_co_players:
  //         formData.conflictResolutionWithCoPlayers,
  //       conflict: Boolean(formData.conflictWithCoach),
  //       conflict_reason: formData.conflictReasonWithCoach,
  //       conflict_resolution: formData.conflictResolutionWithCoach,
  //       physical_skills: formData.physicalSkills,
  //       psychological_skills: formData.psychologicalSkills,
  //       consultant: {
  //         name: formData.consultantName,
  //       },
  //       competition_history: sportsExperiences,
  //       big_five: responses,
  //     };

  //     formDataToSend.append("player", JSON.stringify(playerData));

  //     const response = await api.createData(
  //       `/student/register/${profileId}/${Key_Key}`,
  //       formDataToSend
  //     );

  //     if (response.status === 201) {
  //       alert("Psychometric test created successfully");
  //       setErrorMessage("");
  //       window.location.reload();
  //     } else {
  //       setErrorMessage("Failed to create psychometric test");
  //       setSuccessMessage("");
  //     }
  //   } catch (error) {
  //     console.error("Error creating psychometric test:", error);
  //     setErrorMessage("Error creating psychometric test");
  //     setSuccessMessage("");
  //   }
  // };

  // console.log("sportsExperiences", sportsExperiences);
  type PlayerData = {
    date: string;
    consultant_name: string;
  };

  type MyComponentProps = {
    playerdata: PlayerData[];
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <Form
          // onSubmit={handleSubmit}
          >
            {currentSection === 1 && (
              <>
                <fieldset>
                  <h2
                    className="text-center mb-4"
                    style={{
                      border: "2px solid white",
                      backgroundColor: "rgb(7, 41, 77)",
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 0px",
                    }}
                  >
                    Initial Assessment Form
                  </h2>
                  {playerdata?.map((item, i) => (
                    <Row key={i}>
                      <Col md={6}>
                        <Form.Group controlId="formDate">
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            // type="date"
                            name="date"
                            value={formatDate(item?.date)}
                            onChange={handleChange}
                            // placeholder="DD/MM/YYYY" // Add placeholder attribute here
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formConsultantName">
                          <Form.Label>Name of Consultant</Form.Label>
                          <Form.Control
                            type="text"
                            name="consultantName"
                            value={item?.consultant_name}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}

                  <h4
                    className="mt-4 "
                    style={{
                      border: "2px solid white",
                      backgroundColor: "rgb(7, 41, 77)",
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 4px",
                    }}
                  >
                    1. Basic data
                  </h4>
                  {playerdata?.map((item, i) => (
                    <Row key={i}>
                      <Col md={6}>
                        <Form.Group controlId="formPlayerName">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="playerName"
                            value={item?.player?.name}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formSports">
                          <Form.Label>Sports</Form.Label>
                          <Form.Control
                            type="text"
                            name="sports"
                            value={item?.player?.sports}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  {playerdata?.map((item, i) => (
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formPlayingSince">
                          <Form.Label>Playing Since</Form.Label>
                          <Form.Control
                            type="text"
                            name="playingSince"
                            value={item?.player?.playing_since}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formDob">
                          <Form.Label>D.O.B</Form.Label>
                          <Form.Control
                            // type="date"
                            name="dob"
                            value={item?.player?.date_of_birth}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  {playerdata?.map((item, i) => (
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formGender">
                          <Form.Label>Gender</Form.Label>
                          <Form.Control
                            type="text"
                            name="gender"
                            value={item?.player?.gender}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formAcademicBackground">
                          <Form.Label>Academic Background</Form.Label>
                          <Form.Control
                            type="text"
                            name="academicBackground"
                            value={item?.player?.academic_background}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  {playerdata?.map((item, i) => (
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formCurrentLevelOfCompetition">
                          <Form.Label>Current Level of Competition</Form.Label>
                          <Form.Control
                            type="text"
                            name="currentLevelOfCompetition"
                            value={item?.player?.current_level_of_competition}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formHighestLevelOfCompetition">
                          <Form.Label>Highest Level of Competition</Form.Label>
                          <Form.Control
                            type="text"
                            name="highestLevelOfCompetition"
                            value={item?.player?.highest_level_of_competition}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  {playerdata?.map((item, i) => (
                    <Row>
                      <Col md={12}>
                        <Form.Group controlId="formHighestScoreAchieved">
                          <Form.Label>
                            Highest Score Achieved (Peak Performance)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="highestScoreAchieved"
                            value={item?.player?.highest_score_achieved}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  {playerdata?.map((item, i) => (
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formCoachName">
                          <Form.Label>Name of the Coach</Form.Label>
                          <Form.Control
                            type="text"
                            name="coachName"
                            value={item?.player?.coachName}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formYearsTrainingUnderCurrentCoach">
                          <Form.Label>
                            No. of Years Training Taken Under Current Coach
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="yearsTrainingUnderCurrentCoach"
                            value={item?.player?.years_of_training}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  <h4
                    className="mt-4 "
                    style={{
                      border: "2px solid white",
                      backgroundColor: "rgb(7, 41, 77)",
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 4px",
                    }}
                  >
                    2. Semi structure interview
                  </h4>

                  {playerdata?.map((item, i) => (
                    <Row key={i}>
                      <Col md={12}>
                        <Form.Group
                          controlId={`formPsychologicalAssistance_${i}`}
                        >
                          <Form.Label>
                            1. Have You Ever Taken Any Psychological Assistance
                            for Performance Enhancement?
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="psychologicalAssistance"
                            value={
                              item?.player?.psychological_assistance?.provided
                              // ? "Yes"
                              // : "No"
                            }
                            onChange={handleChange}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      {item?.player?.psychological_assistance?.provided ===
                        "Yes" && (
                        <Row>
                          <Col md={12}>
                            <Form.Group
                              controlId={`formPsychologicalAssistanceReason_${i}`}
                            >
                              <Form.Label>
                                Reason for Taking Assistance, Follow Ups,
                                Effectiveness of Counselling Session
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="psychologicalAssistanceReason"
                                value={
                                  // formData[i]?.psychologicalAssistanceReason ||
                                  item?.player?.psychological_assistance
                                    ?.reason || ""
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      {item?.player?.psychological_assistance?.provided ===
                        "No" && (
                        <Row>
                          <Col md={12}>
                            <Form.Group
                              controlId={`formWouldLikePsychologicalAssistance_${i}`}
                            >
                              <Form.Label>
                                Would You Like to Take Psychological Assistance?
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="psychologicalAssistanceReason"
                                value={
                                  // formData[i]?.psychologicalAssistanceReason ||
                                  item?.player?.psychological_assistance
                                    ?.reason || ""
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </Row>
                  ))}
                  {/* {playerdata?.map((item, i) => (
                    <Row>
                      <Col md={12}>
                        <Form.Group controlId="formPsychologicalAssistance">
                          <Form.Label>
                            1. Have You Ever Taken Any Psychological Assistance
                            for Performance Enhancement?
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="psychologicalAssistance"
                            value={formData.psychologicalAssistance}
                            onChange={handleChange}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      {formData.psychologicalAssistance === "Yes" && (
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="formPsychologicalAssistanceReason">
                              <Form.Label>
                                Reason for Taking Assistance, Follow Ups,
                                Effectiveness of Counselling Session
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="psychologicalAssistanceReason"
                                value={
                                  item?.player?.psychological_assistance?.reason
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      {formData.psychologicalAssistance === "No" && (
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="formWouldLikePsychologicalAssistance">
                              <Form.Label>
                                Would You Like to Take Psychological Assistance?
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="psychologicalAssistanceReason"
                                value={
                                  item?.player?.psychological_assistance?.reason
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </Row>
                  ))} */}

                  {playerdata?.map((item, i) => (
                    <Row className="mt-4">
                      <Col md={12}>
                        <Form.Group controlId="formSelfAssessment">
                          <Form.Label>
                            2. How Do You Self-Assess Your Own Performance?
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="selfAssessment"
                            value={item?.player?.self_assessment}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}

                  {playerdata?.map((item, i) => (
                    <div key={i}>
                      <Row className="mt-4">
                        <Col md={12}>
                          <Form.Group controlId="formSatisfactionWithPerformance">
                            <Form.Label>
                              3. Are you satisfied with your performance?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="satisfactionWithPerformance"
                              value={
                                item?.player?.satisfaction_with_performance
                                //  ===
                                // true
                                //   ? "Yes"
                                //   : "No"
                              }
                              onChange={handleChange}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* {!item?.player?.satisfaction_with_performance && ( */}
                      {item?.player.satisfaction_with_performance === "Yes" && (
                        <Row className="mt-4">
                          <Col md={12}>
                            <Form.Group controlId="formHelpToMaintainImprovePerformance">
                              <Form.Label>
                                Would you like to take help to maintain/improve
                                performance?
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="helpToMaintainImprovePerformance"
                                value={
                                  item?.player
                                    ?.help_to_maintain_improve_performance || ""
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {item?.player?.satisfaction_with_performance === "No" && (
                        <Row className="mt-4">
                          <Col md={12}>
                            <Form.Group controlId="formReasonsForDissatisfaction">
                              <Form.Label>
                                Reasons for dissatisfaction:
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name="reasonsForDissatisfaction"
                                value={
                                  item?.player?.reasons_for_dissatisfaction ||
                                  ""
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}
                </fieldset>
                <div className="text-right mt-5">
                  <Button onClick={handleNext} style={{ float: "right" }}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {/* Page 2 */}
            {currentSection === 2 && (
              <>
                <fieldset>
                  <Row className="mt-4">
                    <Col md={12}>
                      <h4
                        className=" mb-4"
                        style={{
                          border: "2px solid white",
                          backgroundColor: "rgb(7, 41, 77)",
                          color: "white",
                          borderRadius: "8px",
                          padding: "5px 4px",
                        }}
                      >
                        3. Previous experience in sports (detailed):
                      </h4>

                      {/* <Form.Group controlId="formPreviousExperience">
                        <Row className="mb-4 text-center">
                          <Col md={3}>
                            <h5 style={{ fontWeight: "bold" }}>Level</h5>
                          </Col>
                          <Col md={3}>
                            <h5 style={{ fontWeight: "bold" }}>
                              Year of Achievement
                            </h5>
                          </Col>
                          <Col md={3}>
                            <h5 style={{ fontWeight: "bold" }}>
                              Medal / Rank / Position
                            </h5>
                          </Col>
                          <Col md={3}>
                            <h5 style={{ fontWeight: "bold" }}>
                              Best Score Achieved
                            </h5>
                          </Col>
                        </Row>
                        {sportsExperiences.map((experience, index) => (
                          <div key={index} className="mb-4">
                            <Row>
                              <Col md={3}>
                                <h5>{experience.level}</h5>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId={`year-${index}`}>
                                  <Form.Control
                                    type="text"
                                    value={experience.year}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "year",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId={`medal-${index}`}>
                                  <Form.Control
                                    type="text"
                                    value={experience.medal}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "medal",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group controlId={`bestscore-${index}`}>
                                  <Form.Control
                                    type="text"
                                    value={experience.bestscore}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "bestscore",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Form.Group> */}

                      <Form.Group controlId="formPreviousExperience">
                        {/* {sportsExperiences.map((experience, index) => ( */}
                        {playerdata?.map((item, i) => (
                          <div key={i} className="mb-4">
                            {item?.player?.competition_history?.map(
                              (lvData: any, i: any) => (
                                <Row>
                                  <Col md={3}>
                                    <h5>{lvData.level}</h5>
                                  </Col>

                                  <Col md={3} key={i}>
                                    <Form.Group>
                                      <Form.Label>
                                        Year of Achievement
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={lvData.year}
                                        // onChange={(e) =>
                                        //   handleInputChange(
                                        //     index,
                                        //     "year",
                                        //     e.target.value
                                        //   )
                                        // }
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Group>
                                      <Form.Label>
                                        Medal / Rank / Position
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={lvData.medal}
                                        // onChange={(e) =>
                                        //   handleInputChange(
                                        //     index,
                                        //     "medal",
                                        //     e.target.value
                                        //   )
                                        // }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group
                                    // controlId={`bestscore-${index}`}
                                    >
                                      <Form.Label>
                                        Best Score Achieved
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={lvData.bestscore}
                                        // onChange={(e) =>
                                        //   handleInputChange(
                                        //     index,
                                        //     "bestscore",
                                        //     e.target.value
                                        //   )
                                        // }
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              )
                            )}
                          </div>
                        ))}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h4
                    className=" mb-4"
                    style={{
                      border: "2px solid white",
                      backgroundColor: "rgb(7, 41, 77)",
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 4px",
                    }}
                  >
                    4. Sports injury (if any):
                  </h4>

                  {playerdata?.map((item, i) => (
                    <Row className="mt-4" key={i}>
                      <Col md={6}>
                        <Form.Group controlId="formSportsInjury">
                          <Form.Label>i. Specify the injury</Form.Label>
                          <Form.Control
                            type="text"
                            name="sportsInjury"
                            value={item?.player.sports_injury.description}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formInjuryTreatment">
                          <Form.Label>
                            ii. Treatment taken for injury
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="injuryTreatment"
                            value={item?.player?.sports_injury.treatment}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  {playerdata?.map((item, i) => (
                    <Row className="mt-4" key={i}>
                      <Col md={6}>
                        <Form.Group controlId="formHealingStatusOfInjury">
                          <Form.Label>iii. Healing status of injury</Form.Label>
                          <Form.Control
                            type="text"
                            name="healingStatusOfInjury"
                            value={item?.player?.sports_injury?.healing_status}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formInjuryProneness">
                          <Form.Label>
                            iV. Injury proneness of athlete (reoccurrence of
                            injury)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="injuryProneness"
                            value={item?.player?.sports_injury?.proneness}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}

                  {/* <Row className="mt-4">
          <Col md={12}>
            <Form.Group controlId="formCoachDescription">
              <Form.Label>Description of coach:</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                name="coachDescription"
                value={formData.coachDescription}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row> */}
                </fieldset>
                <div className="text-right mt-5">
                  <Button onClick={handlePrev}>Previous</Button>
                  <Button onClick={handleNext} style={{ float: "right" }}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {currentSection === 3 && (
              <>
                <fieldset>
                  <h4
                    className="mb-4"
                    style={{
                      border: "2px solid white",
                      backgroundColor: "rgb(7, 41, 77)",
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 4px",
                    }}
                  >
                    5. Description of coach:
                  </h4>
                  {playerdata?.map((item, i) => (
                    <Row className="mt-4">
                      <Col md={6}>
                        <Form.Group controlId="formCoachSupportReasons">
                          <Form.Label>
                            a. Support from coach with its reasons
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="coachSupportReasons"
                            value={
                              item?.player?.coachDescription?.support_reasons
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formCoachNonSupportReasons">
                          <Form.Label>
                            b. Non-support from coach with its reasons
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="coachNonSupportReasons"
                            value={
                              item?.player?.coachDescription
                                ?.non_support_reasons
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
                  <h4
                    className="mt-4"
                    style={{
                      border: "2px solid white",
                      backgroundColor: "rgb(7, 41, 77)",
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 4px",
                    }}
                  >
                    {" "}
                    6. Social Relationship
                  </h4>

                  {playerdata?.map((item, i) => (
                    <div key={i}>
                      <Row className="mt-4">
                        <Col md={12}>
                          <Form.Group controlId={`formConflictWithCoach-${i}`}>
                            <Form.Label>
                              i. Do you have any conflict with coach?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name={`conflictWithCoach-${i}`}
                              value={
                                item?.player.conflict
                                // == true ? "Yes" : "No"
                              }
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      {item?.player.conflict === "Yes" && (
                        <Row className="mt-4">
                          <Col md={12}>
                            <Form.Group
                              controlId={`formConflictReasonWithCoach-${i}`}
                            >
                              <Form.Label>
                                a. Specify the reasons for conflict and its
                                intensity:
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name={`conflictReasonWithCoach-${i}`}
                                value={item?.player.conflict_reason}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {item?.player.conflict === "No" && (
                        <Row className="mt-4">
                          <Col md={12}>
                            <Form.Group
                              controlId={`formConflictResolutionWithCoach-${i}`}
                            >
                              <Form.Label>
                                b. How do you cope up with difficult/conflicting
                                situations where your views are not matching
                                with your coach?
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name={`conflictResolutionWithCoach-${i}`}
                                value={item.player.conflict_resolution}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}

                  {/* {formData.conflictWithCoach === "Yes" && (
                    <Row className="mt-4">
                      <Col md={12}>
                        <Form.Group controlId="formConflictReasonWithCoach">
                          <Form.Label>
                            a. Specify the reasons for conflict and its
                            intensity:
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="conflictReasonWithCoach"
                            value={formData.conflictReasonWithCoach}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {formData.conflictWithCoach === "No" && (
                    <Row className="mt-4">
                      <Col md={12}>
                        <Form.Group controlId="formConflictResolutionWithCoach">
                          <Form.Label>
                            b. How do you cope up with difficult/conflicting
                            situations where your views are not matching with
                            your coach?
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="conflictResolutionWithCoach"
                            value={formData.conflictResolutionWithCoach}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )} */}
                  {playerdata?.map((item, i) => (
                    <div>
                      <Row className="mt-4" key={i}>
                        <Col md={12}>
                          <Form.Group
                            controlId={`formConflictWithCoPlayers-${i}`}
                          >
                            <Form.Label>
                              ii. Do you have any conflict with co-players?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name={`conflictWithCoPlayers-${i}`}
                              value={
                                item?.player?.conflict_with_co_players
                                // == true
                                //   ? "Yes"
                                //   : "No"
                              }
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      {item?.player?.conflict_with_co_players === "Yes" && (
                        <Row className="mt-4">
                          <Col md={12}>
                            <Form.Group
                              controlId={`formConflictReasonWithCoPlayers-${i}`}
                            >
                              <Form.Label>
                                a. Reasons for conflict and how do you aim to
                                solve it?
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name={`conflictReasonWithCoPlayers-${i}`}
                                value={
                                  item?.player?.conflict_reason_with_co_players
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {item?.player?.conflict_with_co_players === "No" && (
                        <Row className="mt-4">
                          <Col md={12}>
                            <Form.Group
                              controlId={`formConflictResolutionWithCoPlayers-${i}`}
                            >
                              <Form.Label>
                                b. How do you adjust with your co-players?
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name={`conflictResolutionWithCoPlayers${i}`}
                                value={
                                  item?.player
                                    ?.conflict_resolution_with_co_players
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}
                </fieldset>
                <div className="text-right mt-5">
                  <Button onClick={handlePrev}>Previous</Button>
                  <Button onClick={handleNext} style={{ float: "right" }}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {/* Page 4 */}
            {currentSection === 4 && (
              <>
                <fieldset>
                  <h4
                    style={{
                      border: "2px solid white",
                      backgroundColor: "rgb(7, 41, 77)",
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 4px",
                    }}
                  >
                    7. Performance Profiling
                  </h4>
                  <p>
                    Rate yourself on the scale of 1 to 10. Where 1 being lowest
                    and 10 being highest
                  </p>
                  <div className="table-responsive">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Physical Skills</th>
                          <th>Rating (1 - 10)</th>
                          <th>Psychological Skills</th>
                          <th>Rating (1 - 10)</th>
                        </tr>
                      </thead>
                      {playerdata?.map((item, i) => (
                        <tbody key={i}>
                          <tr>
                            <td>Flexibility</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="flexibility"
                                value={
                                  item?.player?.physical_skills?.flexibility
                                }
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Self-confidence</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="self_confidence"
                                value={
                                  item?.player?.psychological_skills
                                    ?.self_confidence
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Agility</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="agility"
                                value={item?.player?.physical_skills?.agility}
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Sustained attention (narrowed/broaden)</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="sustained_attention"
                                value={
                                  item?.player?.psychological_skills
                                    ?.sustained_attention
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Arm strength</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="arm_strength"
                                value={
                                  item?.player?.physical_skills?.arm_strength
                                }
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Role efficacy</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="role_efficacy"
                                value={
                                  item?.player?.psychological_skills
                                    ?.role_efficacy
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Thighs strength</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="thighs_strength"
                                value={
                                  item?.player?.physical_skills?.thighs_strength
                                }
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Concentration</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="concentration"
                                value={
                                  item?.player?.psychological_skills
                                    ?.concentration
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Neuromuscular coordination</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="neuromuscular_coordination"
                                value={
                                  item?.player?.physical_skills
                                    ?.neuromuscular_coordination
                                }
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Stress management</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="stress_management"
                                value={
                                  item?.player?.psychological_skills
                                    ?.stress_management
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Body movement control</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="body_movement_control"
                                value={
                                  item?.player?.physical_skills
                                    ?.body_movement_control
                                }
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Rebound ability</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="rebound_ability"
                                value={
                                  item?.player?.psychological_skills
                                    ?.rebound_ability
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Speed</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="speed"
                                value={item?.player?.physical_skills?.speed}
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Commitment to game</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="commitment_to_game"
                                value={
                                  item?.player?.psychological_skills
                                    ?.commitment_to_game
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Stamina</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="stamina"
                                value={item?.player?.physical_skills?.stamina}
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Anxiety</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="anxiety"
                                value={
                                  item?.player?.psychological_skills?.anxiety
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Any other:</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="other_physical_skills"
                                value={
                                  item?.player?.physical_skills
                                    ?.other_physical_skills
                                }
                                onChange={handlePhysicalSkillsChange}
                              />
                            </td>
                            <td>Any other:</td>
                            <td>
                              <Form.Control
                                type="text"
                                name="other_psychological_skills"
                                value={
                                  item?.player?.psychological_skills
                                    ?.other_psychological_skills
                                }
                                onChange={handlePsychologicalSkillsChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                  <div className="text-right mt-5">
                    <Button onClick={handlePrev}>Previous</Button>
                    {/* <Button onClick={handleNext} style={{ float: "right" }}>
                      Next
                    </Button> */}
                  </div>
                </fieldset>
              </>
            )}
            {/* {currentSection === 5 && (
              <>
                <h2
                  className="text-center"
                  style={{
                    border: "2px solid white",
                    backgroundColor: "rgb(7, 41, 77)",
                    color: "white",
                    borderRadius: "8px",
                    padding: "5px 0px",
                  }}
                >
                  {" "}
                  Big Five Personality Test
                </h2>
                <p>
                  <strong>NOTE:</strong>
                  <br />
                  Assesses the Big Five dimensions of personality: Emotional
                  stability, Extraversion, Openness, Conscientiousness and
                  Agreeableness, each of which is comprised of six facets. The
                  Personality Structure Inventory enables future behavioral
                  tendencies to be predicted. The modular structure of the test
                  enables the individual dimensions and their facets to be
                  assessed separately
                </p>
                <p>
                  Here are a number of characteristics that may or may not apply
                  to you. For example, do you agree that you are someone who
                  likes to spend time with others? Please write a number next to
                  each statement to indicate the extent to which you agree or
                  disagree with that statement.
                </p>
                <p>
                  Strongly Disagree = 1, Disagree = 2, Neutral = 3, Agree = 4,
                  Strongly Agree = 5.
                </p>
                {Object.entries(sections).map(([section, questions], i) => (
                  <div key={i}>
                    <h3
                      style={{
                        border: "2px solid white",
                        backgroundColor: "rgb(7, 41, 77)",
                        color: "white",
                        borderRadius: "8px",
                        padding: "5px 5px",
                        display: "inline-block",
                      }}
                    >
                      {section}:
                    </h3>
                    {renderQuestions(section, questions)}
                  </div>
                ))}
                <div className="text-right mt-5">
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ float: "right" }}
                  >
                    Submit
                  </Button>
                  <Button onClick={handlePrev}>Previous</Button>
                </div>
                {successMessage && (
                  <p style={{ color: "green" }}>{successMessage}</p>
                )}
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </>
            )} */}

            {/* <Button variant="primary" type="submit">
              Submit
            </Button> */}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ViewProfileDetails;
