import React, { useState, useEffect } from "react";
import { Form, Row, Container, Card, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { RootState, AppDispatch } from "../../../redux/store";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from "../../../apiservices/apiService";

interface TestItem {
  _id: string;
  SN: number;
  name: string;
  sports: string;
  date_of_birth: string;
  date: string;
  result: string;
  rank: number;
  player: any;
}

const TestSummery = () => {
  const { user, userLoggedIn, loading, error, _id } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      _id: state.Auth._id,
    })
  );

  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user?.moniterd_ttl
    : user?.user
    ? user?.user?.moniterd_ttl
    : "";

  const [tests, setTests] = useState<TestItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tests.slice(indexOfFirstItem, indexOfLastItem);

  const handleViewClick = (id: string) => {
    // console.log("Navigate to page for person with ID:");
    // Example of using React Router to navigate
    // <Link to={`/person/${id}`}>View</Link>
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(tests.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  // const [data, setData] = useState([]);
  const handleFetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/student/tests`);
      let res = await response.json();

      setTests(res.tests.slice().reverse());
      //  console.log(res, "res");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleFetchData();
  }, []);
  // console.log(tests, "data");

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const calculateAge = (dob: string) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dobDate.getFullYear();
    const monthDiff = currentDate.getMonth() - dobDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dobDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const formatDate = (date: string) => {
    const formattedDate = new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <div className="container table-responsive">
            <h1 className="text-center mt-4 mb-4">Result</h1>
            <table className="table ">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>NAME</th>
                  <th>SPORTS</th>
                  <th>AGE</th>
                  <th>DATE</th>
                  <th>RESULT</th>
                  <th>RANK</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>
                      <Link
                        to={`/page/view-profile-detilas/${item?.player._id}`}
                      >
                        {item.player.name}
                      </Link>
                    </td>
                    <td>{item.player.sports}</td>
                    <td>{calculateAge(item.player.date_of_birth)}</td>
                    <td>{formatDate(item.date)}</td>

                    {/* <td>
                      {item.RESULT === "Win" ? (
                        <span style={{ color: "green" }}>{item.RESULT}</span>
                      ) : (
                        <span style={{ color: "red" }}>{item.RESULT}</span>
                      )}
                    </td> */}

                    <td>
                      <button>
                        <Link to={`/page/view-result/${item?.player._id}`}>
                          View
                        </Link>
                      </button>
                    </td>
                    <td>{}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ul className="pagination">
              <li className="page-item">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="page-link"
                >
                  Previous
                </button>
              </li>
              {pageNumbers.map((number) => (
                <li key={number} className="page-item">
                  <button
                    onClick={() => paginate(number)}
                    style={{
                      backgroundColor:
                        currentPage === number ? "#007bff" : "transparent",
                      color: currentPage === number ? "#fff" : "#007bff",
                    }}
                    className="page-link"
                  >
                    {number}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === pageNumbers.length}
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TestSummery;
