import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Table,
  Button,
  Form,
  Pagination,
  InputGroup,
} from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import api from "../../../utils/ApiMethod";
import { Link } from "react-router-dom";

interface BatteryTestDetailsType {
  _id: string;
  id: number;
  studentName: string;
  fatherName: string;
  notice_doc: string;
  School_Name: string;
  height?: string;
  weight?: string;
  verticalJump?: string;
  thirtyMFlingStarts?: string;
  footballBallThrow5No?: string;
  shuttleRun10MX6?: string;
  standingBroadJump?: string;
  eightHundredMetersRun?: string;
}

type InputValidityType = "blank" | "invalid" | "valid";

const BatteryTest = () => {
  const [schoolProfiles, setSchoolProfiles] = useState<
    BatteryTestDetailsType[]
  >([]);
  const [inputData, setInputData] = useState<{
    [key: number]: {
      height: string;
      weight: string;
      verticalJump: string;
      thirtyMFlingStarts: string;
      footballBallThrow5No: string;
      shuttleRun10MX6: string;
      standingBroadJump: string;
      eightHundredMetersRun: string;
    };
  }>({});
  const [isEditItem, setIsEditItem] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValidity, setInputValidity] = useState<{
    [key: number]: {
      height: InputValidityType;
      weight: InputValidityType;
      verticalJump: InputValidityType;
      thirtyMFlingStarts: InputValidityType;
      footballBallThrow5No: InputValidityType;
      shuttleRun10MX6: InputValidityType;
      standingBroadJump: InputValidityType;
      eightHundredMetersRun: InputValidityType;
    };
  }>({});

  useEffect(() => {
    const savedProfiles = localStorage.getItem("schoolProfiles");
    if (savedProfiles) {
      setSchoolProfiles(JSON.parse(savedProfiles));
    }

    const savedInputData = localStorage.getItem("inputData");
    if (savedInputData) {
      setInputData(JSON.parse(savedInputData));
    }
  }, []);

  // console.log(schoolProfiles, "schoolProfiles");

  // Save data to local storage
  const saveDataToLocalStorage = (
    profiles: BatteryTestDetailsType[],
    data: any
  ) => {
    localStorage.setItem("schoolProfiles", JSON.stringify(profiles));
    localStorage.setItem("inputData", JSON.stringify(data));
  };
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  let profileId = user?._id ?? user?.user?._id ?? "";
  const Key_Key = user?.moniterd_ttl ?? user?.user?.moniterd_ttl ?? "";
  // console.log(user, "USER");

  // Fetch notices data from the API
  const fetchSchoolProfiles = async () => {
    try {
      const response = await api.fetchDataAuth(
        `/getstudent/${profileId}/${user?.Udise_Code}/${Key_Key}`
      );
      setSchoolProfiles(response?.data);
    } catch (error) {
      console.error("Error fetching notices:", error);
    }
  };

  useEffect(() => {
    fetchSchoolProfiles();
  }, [user]);

  const handleInputChange = (id: number, field: string, value: string) => {
    // Validate input to ensure only numbers with up to two decimal places are entered
    const regex = /^\d*\.?\d{0,2}$/; // Regex to allow up to two decimal places
    // Check if the input consists only of digits
    const containsOnlyNumbers = /^[0-9]*$/;
    let isValid: InputValidityType = "blank";

    if (value === "") {
      isValid = "blank";
    } else if (
      regex.test(value) || // Check if value matches the regex
      containsOnlyNumbers.test(value) // Check if value consists only of digits
    ) {
      isValid = "valid";
    } else {
      isValid = "invalid";
    }

    setInputData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: value,
      },
    }));

    setInputValidity((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: isValid,
      },
    }));
  };

  const handleSave = (id: number) => {
    // console.log("Saved data for ID:", id, inputData[id]);

    // Check if any of the inputs are invalid
    const invalidInputs = Object.values(inputValidity[id]).some(
      (isValid) => typeof isValid === "boolean" && isValid !== true
    );

    if (invalidInputs) {
      alert("Please ensure all inputs are valid before saving!");
      return;
    }

    // Update schoolProfiles state
    const updatedProfiles = schoolProfiles.map((profile) => {
      if (profile.id === id) {
        return {
          ...profile,
          ...inputData[id],
        };
      }
      return profile;
    });

    setSchoolProfiles(updatedProfiles);

    // Save data to local storage
    saveDataToLocalStorage(updatedProfiles, inputData);

    // Show alert message
    alert("Data saved successfully!");
  };

  // console.log(schoolProfiles, "schoolProfiles");

  const handleSubmit = async (id: number) => {
    const dataToSubmit = inputData[id];
    // try {
    //   const response = await api.createData(`/api/submit/${id}`, dataToSubmit);
    //   console.log("Data submitted for ID:", id, response.data);
    // } catch (error) {
    //   console.error("Error submitting data:", error);
    // }
  };
  const editItem = async (id: string) => {
    setIsEditItem(id);
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  // console.log(isEditItem, "IsEditItem");

  const paginate = (
    items: BatteryTestDetailsType[],
    pageNumber: number,
    itemsPerPage: number
  ) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    return items?.slice(startIndex, startIndex + itemsPerPage);
  };

  const filteredItems = schoolProfiles?.filter((profile) =>
    profile.studentName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentItems = paginate(filteredItems, currentPage, itemsPerPage);

  const getInputBorderColor = (validity: InputValidityType) => {
    switch (validity) {
      case "blank":
        return "blue";
      case "valid":
        return "green";
      case "invalid":
        return "red";
      default:
        return "";
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "School", path: "/dashboard/student" },
          {
            label: "School",
            path: "/dashboard/student",
            active: true,
          },
        ]}
        title={"Dashboard"}
      />

      <Row>
        <Col>
          <Card
            style={{
              background: "#07294d",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            <Card.Body>
              <h1 className="center" style={{ color: "#ffffff" }}>
                Please Enter Your BATTERY TEST Score Details
              </h1>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="mb-3" style={{ maxWidth: "500px" }}>
            <InputGroup.Text>Search</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search by student name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <Container>
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th>ID</th>
                    <th>Student Name</th>
                    <th>Father Name</th>
                    <th>Height (Cm)</th>
                    <th>Weight (Kg)</th>
                    <th>Vertical Jump (cm)</th>
                    <th>30 Mtr sprint(sec)</th>
                    <th>M-Ball Throw</th>
                    <th>6X10 Mtr shuttle run</th>
                    <th>Standing Broad jump</th>
                    <th>800 mts Run</th>
                    <th>Save</th>
                    <th>Submit</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((profile, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <Link
                          to={`/pages/student-profile/${profile?._id}`}

                          // onClick={() =>
                          //   profile?._id !== undefined && editItem(profile._id)
                          // }
                        >
                          {profile.studentName}
                        </Link>
                      </td>
                      <td>{profile.fatherName}</td>
                      <td>
                        <Form.Control
                          type="text"
                          value={inputData[profile.id]?.height || ""}
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "height",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.height
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={inputData[profile.id]?.weight || ""}
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "weight",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.weight
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={inputData[profile.id]?.verticalJump || ""}
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "verticalJump",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.verticalJump
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={
                            inputData[profile.id]?.thirtyMFlingStarts || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "thirtyMFlingStarts",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.thirtyMFlingStarts
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={
                            inputData[profile.id]?.footballBallThrow5No || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "footballBallThrow5No",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.footballBallThrow5No
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={inputData[profile.id]?.shuttleRun10MX6 || ""}
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "shuttleRun10MX6",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.shuttleRun10MX6
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={inputData[profile.id]?.standingBroadJump || ""}
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "standingBroadJump",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.standingBroadJump
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={
                            inputData[profile.id]?.eightHundredMetersRun || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              profile.id,
                              "eightHundredMetersRun",
                              e.target.value
                            )
                          }
                          style={{
                            borderColor: getInputBorderColor(
                              inputValidity[profile.id]?.eightHundredMetersRun
                            ),
                          }}
                        />
                      </td>
                      <td>
                        <Button onClick={() => handleSave(profile.id)}>
                          Save
                        </Button>
                      </td>
                      <td>
                        <Button onClick={() => handleSubmit(profile.id)}>
                          Submit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} />
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {[
                  ...Array(
                    Math.ceil(
                      schoolProfiles?.length
                        ? schoolProfiles?.length
                        : 0 / itemsPerPage
                    )
                  ),
                ].map((item, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(
                      schoolProfiles?.length
                        ? schoolProfiles?.length
                        : 0 / itemsPerPage
                    )
                  }
                />
                <Pagination.Last
                  onClick={() =>
                    handlePageChange(
                      Math.ceil(
                        schoolProfiles?.length
                          ? schoolProfiles?.length
                          : 0 / itemsPerPage
                      )
                    )
                  }
                />
              </Pagination>
            </Container>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default BatteryTest;
