import ApiService from "./ApiService";

const fetchData = async (endpoint: string): Promise<any> => {
  try {
    const response = await ApiService.get(endpoint);

    return response;
  } catch (error) {
    // Handle errors
    return error;
  }
};

const fetchDataAuth = async (endpoint: string): Promise<any> => {
  try {
    const response = await ApiService.get(endpoint, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    // Handle errors
    return error;
  }
};

const createData = async (endpoint: string, data: FormData): Promise<any> => {
  try {
    const response = await ApiService.post(endpoint, data, {
      withCredentials: true,
    });
    // console.log(response);

    return response;
  } catch (error) {
    // console.log(error);
    // Handle errors
    return error;
  }
};

const updateData = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await ApiService.put(endpoint, data, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    // Handle errors
    return error;
  }
};

const updatePatchData = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await ApiService.patch(endpoint, data, {
      withCredentials: true,
    });
    // console.log(response, "response");
    return response;
  } catch (error) {
    // Handle errors
    console.log(error, "error");
    return error;
  }
};

const updatePatchDataDelete = async (
  endpoint: string,
  data: any
): Promise<any> => {
  try {
    const response = await ApiService.patch(endpoint, data, {
      withCredentials: true,
    });
    // console.log("delete from method");

    return response;
  } catch (error) {
    // Handle errors
    return error;
  }
};

const deleteData = async (endpoint: string): Promise<any> => {
  try {
    const response = await ApiService.delete(endpoint, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    // Handle errors
    return error;
  }
};

const api = {
  fetchData,
  fetchDataAuth,
  createData,
  updateData,
  deleteData,
  updatePatchData,
  updatePatchDataDelete,
};

export default api;
